
const raw = `ano integer,
cod_noti integer,
dt_notif date,
dt_envlo date,
dt_digit date,
sem_noti float,
tipo_lam varchar(255),
uf_notif varchar(255),
mun_noti integer,
cod_unin float,
cod_agen float,
dt_nasci date,
id_pacie float,
id_dimea varchar(255),
sexo varchar(255),
raca varchar(255),
pais_res varchar(255),
uf_resid varchar(255),
mun_resi float,
loc_resi float,
sintomas varchar(255),
dt_sinto date,
cod_ocup varchar(255),
uf_infec varchar(255),
pais_inf varchar(255),
mun_infe float,
loc_infe float,
dt_exame date,
res_exam varchar(255),
qtd_para float,
qtd_cruz float,
dt_trata date,
esquema varchar(255),
hemoparasi varchar(255),
exame varchar(255),
examinador varchar(255),
id_lvc varchar(255),
gestante varchar(255),
vivax varchar(255),
falciparum varchar(255),
niv_esco varchar(255),
id_pacie_anos integer,
nu_predi_infe float,
nu_habit_infe float,
zona_infe varchar(255),
categori_infe varchar(255),
nu_predi_resi float,
nu_habit_resi float,
zona_resi varchar(255),
categori_resi varchar(255)`;

export default raw;

